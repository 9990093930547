* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing   : border-box;
    box-sizing        : border-box;
}

img {
    max-width: 100%;
}

a.fiftypercent img {
    max-width: 500px;
    width    : 100%;
}

p.fiftypercent img {
    max-width: 500px;
    width    : 100%;
}

li {
    list-style: none;
    margin    : 0;
}

#content li {
    list-style-type: circle;
    margin-left    : 30px;
}

ul.thumbnails {
    margin               : 0;
    -webkit-padding-start: 0;
}

#content .thumbnails li {
    list-style: none;
    margin    : 0;
}

#content .list-unstyled li {
    list-style: none;
    margin    : 0;
}

.mi-block {
    width : 100%;
    float : left;
    margin: 0 0 5px;
}

.headerbits {
    width: 100%;
}
// #tabbedpanels1,
// #tabbedpanels2 {
//     width: 100%;
//     /* max-width: 600px; */
//     float: left;
// }
//
// #tabbedpanels1 div,
// #tabbedpanels2 div {
//     width  : 100%;
//     padding: 8px 0 8px 32px;
//     float  : left;
// }
//
// #tabbedpanels1 li,
// #tabbedpanels2 li {
//     list-style : outside;
//     margin-left: 16px;
//     width      : 98%;
//     float      : left;
//     margin-top : 4px;
// }
//
// #tabbedpanels1 h3 a,
// #tabbedpanels2 h3 a {
//     width           : 100%;
//     float           : left;
//     position        : relative;
//     padding         : 8px 8px 8px 38px;
//     background-color: #FFF;
//     border-bottom   : 1px solid #ccc;
//     font-size       : 16px;
// }
//
// #tabbedpanels1 h3 a:before,
// #tabbedpanels2 h3 a:before {
//     position        : absolute;
//     padding         : 0 0 4px 2px;
//     top             : 8px;
//     left            : 4px;
//     content         : '+';
//     font-size       : 25px;
//     background-color: #004D6B;
//     border-radius   : 50%;
//     width           : 28px;
//     height          : 28px;
//     text-align      : center;
//     color           : #fff;
// }
//
// #tabbedpanels1 .open a,
// #tabbedpanels2 .open a {
//     width           : 100%;
//     float           : left;
//     position        : relative;
//     padding         : 8px 8px 8px 38px;
//     background-color: #004D6B;
//     color           : #fff;
//     font-size       : 16px;
// }
//
// #tabbedpanels1 .open a:before,
// #tabbedpanels2 .open a:before {
//     position        : absolute;
//     top             : 8px;
//     left            : 4px;
//     content         : '-';
//     font-size       : 24px;
//     background-color: #fff;
//     color           : #004D6B;
// }

legend {
    font-size: 18px;
    padding  : 7px 0;
}

label {
    font-size  : 12px;
    font-weight: normal;
}

input[type="text"].form-control,
input[type="password"].form-control,
input[type="datetime"].form-control,
input[type="datetime-local"].form-control,
input[type="date"].form-control,
input[type="month"].form-control,
input[type="time"].form-control,
input[type="week"].form-control,
input[type="number"].form-control,
input[type="email"].form-control,
input[type="url"].form-control,
input[type="search"].form-control,
input[type="tel"].form-control,
input[type="color"].form-control,
select.form-control,
textarea.form-control {
    font-size: 12px;
}

.input-group .dropdown-menu,
.input-group .popover,
.input-group input,
.input-group select {
    font-size: 12px;
}

.input-group .input-group-addon {
    font-size: 12px;
    height   : 30px;
}
/* Fix some bootstrap issues */
span.hidden-lg,
span.hidden-md,
span.hidden-sm,
span.hidden-xs {
    display: inline;
}

.nav-tabs {
    margin-bottom: 15px;
}

div.required .control-label:before {
    content    : '* ';
    color      : #F00;
    font-weight: bold;
}
/* Gradent to all drop down menus */
.dropdown-menu li > a:hover {
    text-decoration : none;
    color           : #ffffff;
    background-color: #3e3e3e;
}
@media (max-width: 478px) {
    #cart .dropdown-menu li > div {
        min-width: 100%;
    }
}

#cart .dropdown-menu li p {
    margin: 20px 0;
}
/* menu */
// #menu {
//     background-color : #678350;
//     background-repeat: repeat-x;
//     min-height       : 40px;
//     position         : relative;
// }
//
// #menu .nav > li > a {
//     color           : #fff;
//     text-shadow     : 0 -1px 0 rgba(0, 0, 0, 0.25);
//     padding         : 10px 15px;
//     min-height      : 15px;
//     background-color: transparent;
// }
//
// #menu .nav > li > a:hover,
// #menu .nav > li.open > a {
//     background-color: rgba(0, 0, 0, 0.1);
// }
//
// #menu .dropdown-menu {
//     padding-bottom: 0;
// }
//
// #menu .dropdown-inner {
//     display: table;
// }
//
// #menu .dropdown-inner ul {
//     display: table-cell;
// }
//
// #menu .dropdown-inner a {
//     min-width  : 160px;
//     display    : block;
//     padding    : 3px 20px;
//     clear      : both;
//     line-height: 20px;
//     color      : #333333;
//     font-size  : 12px;
// }
//
// #menu .dropdown-inner li a:hover {
//     color: #FFFFFF;
// }
//
// #menu .see-all {
//     display              : block;
//     margin-top           : 0.5em;
//     border-top           : 1px solid #DDD;
//     padding              : 3px 20px;
//     -webkit-border-radius: 0 0 4px 4px;
//     -moz-border-radius   : 0 0 4px 4px;
//     border-radius        : 0 0 3px 3px;
//     font-size            : 12px;
// }
//
// #menu .see-all:focus,
// #menu .see-all:hover {
//     text-decoration : none;
//     color           : #ffffff;
//     background-color: #229ac8;
// }
//
// #menu #category {
//     float       : left;
//     padding-left: 15px;
//     font-size   : 16px;
//     font-weight : 700;
//     line-height : 40px;
//     color       : #fff;
//     text-shadow : 0 1px 0 rgba(0, 0, 0, 0.2);
// }
//
// #menu .btn-navbar {
//     font-size       : 15px;
//     font-stretch    : expanded;
//     color           : #FFF;
//     padding         : 2px 18px;
//     float           : right;
//     background-color: #3F3F3F;
// }
//
// #menu .btn-navbar.disabled,
// #menu .btn-navbar:active,
// #menu .btn-navbar:focus,
// #menu .btn-navbar:hover,
// #menu .btn-navbar[disabled] {
//     color           : #ffffff;
//     background-color: #777;
// }
// @media (min-width: 768px) {
//     #menu .dropdown:hover .dropdown-menu {
//         display: block;
//     }
// }
@media (max-width: 767px) {
    // #menu {
    //     border-radius: 4px;
    // }
    //
    // #menu div.dropdown-inner > ul.list-unstyled {
    //     display: block;
    // }
    //
    // #menu div.dropdown-menu {
    //     margin-left     : 0 !important;
    //     padding-bottom  : 10px;
    //     background-color: rgba(0, 0, 0, 0.1);
    // }
    //
    // #menu .dropdown-inner {
    //     display: block;
    // }
    //
    // #menu .dropdown-inner a {
    //     width: 100%;
    //     color: #fff;
    // }
    //
    // #menu .dropdown-menu a:hover,
    // #menu .dropdown-menu ul li a:hover {
    //     background: rgba(0, 0, 0, 0.1);
    // }
    //
    // #menu .see-all {
    //     margin-top   : 0;
    //     border       : none;
    //     border-radius: 0;
    //     color        : #fff;
    // }
    .searcharea {
        float     : left;
        width     : 48%;
        margin-top: 4px;
    }

    .cartarea {
        float     : right;
        width     : 48%;
        margin-top: 4px;
    }

    #logo img {
        width     : auto;
        max-width : 340px;
        width     : 100%;
        height    : auto;
        text-align: center;
    }
}
/* content */
#content {
    min-height: 600px;
}

#content img {
    width: 100%;
}

#content .panel-collapse img {
    width: auto;
}

#content td.text-center img {
    width: auto;
}

#content .thumbnail img {
    width: auto;
}
/* alert */
.alert {
    padding: 8px 14px;
}
/* breadcrumb */
.breadcrumb {
    margin : 0 0 20px;
    padding: 8px 0;
    border : 1px solid #ddd;
}

.breadcrumb i {
    font-size: 15px;
}

.breadcrumb > li {
    text-shadow: 0 1px 0 #FFF;
    padding    : 0 20px;
    position   : relative;
    white-space: nowrap;
}

.breadcrumb > li + li:before {
    content: '';
    padding: 0;
}

.breadcrumb > li:after {
    content          : '';
    display          : block;
    position         : absolute;
    top              : -3px;
    right            : -5px;
    width            : 26px;
    height           : 26px;
    border-right     : 1px solid #DDD;
    border-bottom    : 1px solid #DDD;
    -webkit-transform: rotate(-45deg);
    -moz-transform   : rotate(-45deg);
    -o-transform     : rotate(-45deg);
    transform        : rotate(-45deg);
}

.pagination {
    margin: 0;
}
/* buttons */
.buttons {
    margin: 1em 0;
}

.btn {
    padding      : 7.5px 12px;
    font-size    : 12px;
    border       : 1px solid #cccccc;
    border-radius: 4px;
    box-shadow   : inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}

.btn-xs {
    font-size: 9px;
}

.btn-sm {
    font-size: 10.2px;
}

.btn-lg {
    padding  : 10px 16px;
    font-size: 15px;
}

.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
    font-size: 12px;
}

.btn-group > .btn-xs {
    font-size: 9px;
}

.btn-group > .btn-sm {
    font-size: 10.2px;
}

.btn-group > .btn-lg {
    font-size: 15px;
}

.btn-default {
    color            : #777;
    text-shadow      : 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color : #e7e7e7;
    background-image : linear-gradient(to bottom, #eeeeee, #dddddd);
    background-repeat: repeat-x;
    border-color     : #dddddd #dddddd #b3b3b3 #b7b7b7;
}

.btn-primary {
    color           : #ffffff;
    text-shadow     : 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #678350;
}

.btn-primary.active,
.btn-primary.disabled,
.btn-primary:active,
.btn-primary:hover,
.btn-primary[disabled] {
    background-color   : #678350;
    background-position: 0 -15px;
}

.btn-warning {
    color           : #ffffff;
    text-shadow     : 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #faa732;
}

.btn-warning.active,
.btn-warning.disabled,
.btn-warning:active,
.btn-warning:hover,
.btn-warning[disabled] {
    box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-danger {
    color            : #ffffff;
    text-shadow      : 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color : #da4f49;
    background-image : linear-gradient(to bottom, #ee5f5b, #bd362f);
    background-repeat: repeat-x;
    border-color     : #bd362f #bd362f #802420;
}

.btn-danger.active,
.btn-danger.disabled,
.btn-danger:active,
.btn-danger:hover,
.btn-danger[disabled] {
    box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-success {
    color           : #ffffff;
    text-shadow     : 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #5bb75b;
}

.btn-success.active,
.btn-success.disabled,
.btn-success:active,
.btn-success:hover,
.btn-success[disabled] {
    box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-info {
    color            : #ffffff;
    text-shadow      : 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color : #df5c39;
    background-repeat: repeat-x;
}

.btn-info.active,
.btn-info.disabled,
.btn-info:active,
.btn-info:hover,
.btn-info[disabled] {
    background-image: none;
    background-color: #df5c39;
}

.btn-link {
    border-color : rgba(0, 0, 0, 0);
    cursor       : pointer;
    color        : #678350;
    border-radius: 0;
}

.btn-link,
.btn-link:active,
.btn-link[disabled] {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    box-shadow      : none;
}

.btn-inverse {
    color           : #ffffff;
    text-shadow     : 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #363636;
}

.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse:active,
.btn-inverse:hover,
.btn-inverse[disabled] {
    background-color: #222222;
    background-image: linear-gradient(to bottom, #333333, #111111);
}
/* list group */
.list-group a {
    border : 1px solid #DDDDDD;
    color  : #888888;
    padding: 8px 12px;
}

.list-group a.active,
.list-group a.active:hover,
.list-group a:hover {
    color     : #fff;
    background: #004D6B;
    border    : 1px solid #DDDDDD;
    /* text-shadow: 0 1px 0 #FFF; */
}
/* carousel */
.carousel-caption {
    color      : #FFFFFF;
    text-shadow: 0 1px 0 #000000;
}

.carousel-control .icon-prev:before {
    content    : '\f053';
    font-family: FontAwesome;
}

.carousel-control .icon-next:before {
    content    : '\f054';
    font-family: FontAwesome;
}
/* fixed colum left + content + right*/
@media (min-width: 768px) {
    #column-left .product-layout .col-md-3 {
        width: 100%;
    }

    #column-left + #content .product-layout .col-md-3 {
        width: 50%;
    }

    #column-left + #content + #column-right .product-layout .col-md-3 {
        width: 100%;
    }

    #content + #column-right .product-layout .col-md-3 {
        width: 100%;
    }
}
/* fixed product layouts used in left and right columns */
#column-left .product-layout,
#column-right .product-layout {
    width: 100%;
}
/* fixed mobile cart quantity input */
.input-group .form-control[name^=quantity] {
    min-width: 50px;
}
@media (max-width: 400px) {
    #cart .dropdown-menu {
        width: 100%;

        table {
            margin-bottom: 10px;
        }

        li > div {
            min-width: 427px;
            padding  : 0 10px;
        }
    }

    .searcharea {
        float     : left;
        width     : 100%;
        margin-top: 4px;
    }

    .cartarea {
        float     : right;
        width     : 100%;
        margin-top: 4px;
    }
}

.embed-container {
    position      : relative;
    padding-bottom: 56.25%;
    height        : 0;
    overflow      : hidden;
    max-width     : 100%;

    embed,
    iframe,
    object {
        position: absolute;
        top     : 0;
        left    : 0;
        width   : 100%;
        height  : 100%;
    }
}

.clear {
    clear: both;
}