.button {
    display         : inline-block;
    padding         : 0.8em 1.5em 0.7em;
    font-family     : $font-heading;
    text-transform  : uppercase;
    background-color: transparent;
    border          : 2px solid $color-text;
    border-radius   : 8px;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        transform: translateX(2px) translateY(2px);
    }

    &-raw {
        @include ctacolor($color-raw);
        // color       : $color-raw;
        // border-color: $color-raw;
    }

    &-recipe {
        @include ctacolor($color-recipe);
        // color: $color-recipe;
        // border-color: $color-
    }
}

.add-to,
.button-group .hidden-xs.hidden-sm.hidden-md {
    font: 0.76em sans-serif;
}

.cart-update-cta {
    border: 1px solid #678350;
}