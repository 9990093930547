.fm-container,
.menu {
    background-color: $color-white;
}

.main-menu {
    margin-bottom: 2rem;
    border-bottom: 10px solid $color-header-bg;
}

.fm-container {
    width  : 100%;
    // top          : 32px;
    // right        : 8px;
    // float        : left;
    // margin-bottom: 12px;
    display: none;
}

.fm-button {
    background-color: #677759;
    display         : none;
    box-sizing      : border-box;
    margin          : 2px 4px;
    padding         : 10px;
    width           : 44px;
    border          : 1px solid #677759;
}

.fm-button:hover {
    cursor            : pointer;
    background-color  : #555;
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, .3);
    -moz-box-shadow   : inset 0 0 8px rgba(0, 0, 0, .3);
    box-shadow        : inset 0 0 8px rgba(0, 0, 0, .3);
}

.fm-button:hover .fm-bar {
    background-color: #fff;
}

.fm-button .fm-bar {
    display         : block;
    width           : 24px;
    height          : 2px;
    background-color: #dfdfdf;
}

.fm-inner,
.fm-outer {
    position: relative;
    width   : 100%;
}

.fm-button .fm-bar+.fm-bar {
    margin-top: 4px;
}

.flexmenu:after,
.flexmenu:before {
    display: table;
    content: " ";
}

.flexmenu:after {
    clear: both;
}

.flexmenu a {
    font-size      : 0.8rem;
    text-transform : uppercase;
    text-decoration: none;
}

.top-menu-link {
    //font-size: 0.9rem;
    font-family: $font-heading;
    //letter-spacing: 0.2px;
}

.flexmenu.fm-toggle.fm-sm {
    display: none;
}

.fm-outer {
    height: 100%;
}

.fm-inner {
    height: 100%;
    left  : 0;
}

.fm-inner.open {
    left: 70%;
}

.fm-inner.open .flexmenu.fm-sm {
    left: 0;
}

.flexmenu.fm-offcanvas.fm-sm {
    z-index           : 100;
    overflow-y        : auto;
    overflow-x        : hidden;
    position          : fixed;
    top               : 0;
    left              : -70%;
    width             : 70%;
    height            : 100%;
    -webkit-box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, .3);
    -moz-box-shadow   : inset -10px 0 10px -10px rgba(0, 0, 0, .3);
    box-shadow        : inset -10px 0 10px -10px rgba(0, 0, 0, .3);
}

.flexmenu.fm-lg {
    width : 100%;
    margin: auto;
}

.flexmenu.fm-lg .navicon:after {
    border          : 1px solid transparent;
    border-top-color: #666;
    content         : "";
    position        : absolute;
    right           : 8px;
    top             : 22px;
}

.flexmenu.fm-lg > ul > li {
    float  : left;
    padding: 0;
}

.flexmenu.fm-lg ul {
    margin         : 0;
    padding        : 0;
    list-style     : none;
    display        : flex;
    flex-wrap      : wrap;
    justify-content: center;
}

.flexmenu.fm-lg li {
    position   : relative;
    white-space: nowrap;
}
/*
.flexmenu.fm-lg ul li:last-child a{

	padding-left: 25px;
	padding-right: 25px;

}
*/
.flexmenu.fm-lg ul li li:last-child {
    float           : left;
    background-color: #555;
}

.flexmenu.fm-lg ul li li:last-child {
    color: #ccc;
}

.flexmenu.fm-lg li ul {
    position        : absolute;
    left            : 0;
    top             : 100%;
    z-index         : 99;
    background-color: #555;
}

.flexmenu.fm-lg li ul .navicon:after {
    border           : 4px solid transparent;
    border-left-color: #666;
    right            : 10px;
    top              : 14px;
}

.flexmenu.fm-lg li ul ul {
    top : 0;
    left: 100%;
}

.flexmenu.fm-lg a {
    position     : relative;
    display      : block;
    padding      : 12px 14px;
    color        : $color-text;
    border-bottom: 4px solid transparent;
}
@media (min-width: 768px) {
    .flexmenu.fm-lg > ul > li:not(:last-child):after {
        content         : "";
        position        : absolute;
        top             : 22%;
        right           : 0;
        width           : 1px;
        height          : 22px;
        background-color: $color-text;
    }
}

.flexmenu.fm-lg ul li ul li a {
    display       : block;
    padding       : 7px 10px;
    font-size     : 1em;
    color         : #ccc;
    text-transform: none;
    border-bottom : 1px solid #333;
    border-right  : none;
}

.flexmenu.fm-lg ul li ul li a:hover {
    display      : block;
    padding      : 7px 10px;
    color        : #fff;
    border-bottom: 1px solid #ccc;
    border-right : none;
}

.flexmenu.fm-lg a:hover {
    color           : #FFF;
    border-bottom   : 4px solid #fff;
    text-decoration : none;
    background-color: $color-text;
}

.flexmenu.fm-lg li ul li {
    min-width: 100%;
}

.flexmenu.fm-lg li ul li:hover {
    //background-color: rgba(256, 256, 256, 1);
}

.flexmenu.fm-lg li ul a {
    color: #ccc;
}

.flexmenu.fm-sm ul {
    color     : #ccc;
    margin    : 0;
    padding   : 0;
    list-style: none;
}

.flexmenu.fm-sm ul li {
    position        : relative;
    border-top      : 1px solid #4d4d4d;
    background-color: #333;
}

.flexmenu.fm-sm ul li:last-child {
    position        : relative;
    border-top      : 1px solid #4d4d4d;
    /* background-color: #677759; */
    background-color: #004d6b;
}

.flexmenu.fm-sm ul li a.active,
.flexmenu.fm-sm ul li a:hover {
    color           : #fff;
    text-decoration : none;
    background-color: #ccc;
}

.flexmenu.fm-sm ul li a {
    display: block;
    padding: 10px 15px;
    //font-size: 16px;
    /* background-color: #333; */
    color  : #ccc;
}

.flexmenu.fm-sm ul li a.active,
.flexmenu.fm-sm ul li a:hover {
    background-color: #677759;
}

.flexmenu.fm-sm ul ul li {
    border-top   : 1px solid #6e6e6e;
    border-bottom: 1px solid #677759;
}

.flexmenu.fm-sm ul ul li a {
    background-color: #555;
}

.flexmenu.fm-sm ul ul li a.active,
.flexmenu.fm-sm ul ul li a:hover {
    color           : #333;
    background-color: #677759;
}

.flexmenu.fm-sm ul ul li ul li {
    border-top   : 1px solid #677759;
    border-bottom: 1px solid #677759;
}

.flexmenu.fm-sm ul ul li ul li a {
    background-color: #777;
}

.flexmenu.fm-sm ul ul li ul li a.active,
.flexmenu.fm-sm ul ul li ul li a:hover {
    background-color: #6a6a6a;
}

.flexmenu.fm-sm .navicon {
    position: absolute;
    top     : 0;
    right   : 4px;
    height  : 40px;
    width   : 48px;
}

.flexmenu.fm-sm .navicon:hover {
    cursor: pointer;
}

.flexmenu.fm-sm .navicon:after {
    border          : 8px solid transparent;
    border-top-color: #949494;
    content         : "";
    position        : absolute;
    right           : 16px;
    top             : 16px;
}

.flexmenu .nav-selected .nav-path-selected {
    color          : #677759;
    border-bottom  : 1px solid #677759;
    text-decoration: none;
}
@media only screen and (max-width: 920px) {
    .fm-container {
        position     : relative;
        top          : 0;
        left         : 0;
        width        : 100%;
        float        : left;
        margin-bottom: 12px;
        min-height   : 40px;
    }
}