@font-face {
    font-family: 'CaeciliaLTStd-Roman';
    src        : local('☺'), url("../fonts/CaeciliaLTStd-Roman.woff") format('woff');
    font-weight: normal;
    font-style : normal;
}
@font-face {
    font-family: 'CaeciliaLTStd-Heavy';
    src        : local('☺'), url("../fonts/CaeciliaLTStd-Heavy.woff") format('woff');
    font-weight: normal;
    font-style : normal;
}
$font-text: 'CaeciliaLTStd-Roman', Arial;
$font-heading: 'CaeciliaLTStd-Heavy', Arial;

html {
    font-size: 16px;
}

body {
    font-family: $font-text;
    color      : $color-text;
    font-size  : 1rem;
    line-height: 1.5;
    width      : 100%;
}

h1,
h2,
h3 {
    font-family: $font-heading;
    font-weight: normal;
}

.price {
    color    : #004D6B;
    font-size: 1rem;
}
/* default font size */
.fa {
    font-size: 1em;
}
/* Override the bootstrap defaults */
h1 {
    font-size     : 2.3rem;
    text-transform: uppercase;
}

h2 {
    font-size: 1.9rem;
}

h3 {
    font-size  : 1.7rem;
    line-height: 1.4;
}

h4 {
    font-size  : 1.5rem;
    line-height: 1.4;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.8rem;
}

a {
    color: inherit;

    #content &:not(.product-title) {
        text-decoration: underline;
    }
}

a:hover {
    text-decoration: none;
}