.instagram-section {
    .owl-carousel {
        margin-top: 2em;
    }

    .owl-wrapper {
        max-width: 100%;
    }

    .owl-carousel {
        background: none;
    }

    .owl-item {
        width       : 24% !important;
        margin-right: 1%;
        @media (max-width: 480px) {
            width        : 100%;
            margin-bottom: 1em;
        }
    }

    .owl-controls {
        visibility: hidden;
    }

    img {
        padding         : 5px;
        background-color: $color-white;
        border-radius   : 2px;
    }

    .social-links {
        font-size: 3em;
    }

    .fa-facebook {
        color: #0c50a0;
    }

    .fa-twitter-square {
        color: #29a2ef;
    }
}

.instagram-tag {
    margin-bottom: 0.5em;
    color        : #104b69;
    text-shadow  : 2px 2px 1px #9a8e8e;
    @media (min-width: 500px) {
        font-size: 3.3em;
    }
}