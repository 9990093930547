.mailchimp-integration {
    margin-top      : 16px;
    padding         : 8px;
    border-radius   : 4px;
    color           : #ccc;
    background-color: #004D6B;
    //background-image: url("https://www.cotswoldraw.com/image/bigdog.png");
    h3,
    h4 {
        color      : #fff;
        font-family: $font-text;
    }

    h3 {
        font-size: 1.2rem;
    }

    h4 {
        font-size : 1rem;
        margin-top: 1em;
    }
}
/* Quick Checkout */
@media (min-width: 701px) {
    .quickcheckoutleft {
        width: 66%;
        float: left;
    }

    .quickcheckoutright {
        width: 32%;
        float: right;
    }

    .quickcheckoutmid {
        width: 100%;
    }
}
@media (max-width: 700px) {
    .quickcheckoutleft {
        width: 100%;
    }

    .quickcheckoutright {
        width: 100%;
    }

    .quickcheckoutmid {
        width: 100%;
    }

    #quickcheckoutconfirm .col-sm-6 {
        width: 100% !important;
    }

    #cart1 {
        padding-top: 0;
    }

    table.quickcheckout-cart {
        overflow: auto;
    }

    .quickcheckout-cart td.image img {
        width : 30px;
        height: auto;
    }
}

.quickcheckout {
    &-content {
        .control-label {
            margin-top   : 1.3em;
            margin-bottom: 0.1em;
        }

        .btn {
            min-height: 35px;
        }

        .text-danger {
            font-size: 0.8em;
        }
    }

    &-preview {
        width: 100%;
    }
}

#coupon-heading {
    font-size: 1em;
    padding  : 0.7em;
}

#button-payment-method {
    margin-left: 1em;
}