$color-text: #0b4e6a;
$color-header-bg: #d8d2c8;
$color-white: #fff;
$color-top-bg: #eeeeee;
$color-raw: #008a73;
$color-on-texture: #252525;
$color-recipe: #b68b25;
$color-top-icons: #888888;
/**/
/// [mixins]
@mixin ctacolor($color) {
    color       : $color;
    border-color: $color;

    &:hover {
        color: $color;
    }
}
@mixin styleText {
    position           : relative;
    max-width          : 100%;
    font-size          : 0;
    color              : transparent;
    text-indent        : -999999px;
    overflow           : hidden;
    background-size    : contain;
    background-repeat  : no-repeat;
    background-position: center;
}