/* footer */
.customer-heading {
    position      : relative;
    margin-bottom : 1em;
    margin-top    : 0;
    color         : #ab5b93;
    text-transform: uppercase;
    text-shadow   : 2px 2px 1px #a7a5a5;
    @media (min-width: 640px) {
        font-size: 3em;
    }

    &:after {
        content         : "";
        display         : block;
        width           : 400px;
        height          : 2px;
        max-width       : 90%;
        margin          : 0.7em auto;
        background-color: #ccc;
    }
}

.main-footer {
    padding-top     : 2em;
    padding-bottom  : 2em;
    font-size       : 0.9em;
    background-color: rgba(0,0,0,.8);
    color           : $color-white;
    @media (min-width: 480px) {
        padding-top        : 10rem;
        padding-bottom     : 0;
        margin-top         : 0;
        background-image   : url("../image/footer/footer-bg-md.jpg");
        background-position: center top;
        background-repeat  : no-repeat;
        background-size    : cover;

        .grass {
            padding         : 2em;
            background-color: rgba(0,0,0,.45);
        }
    }
    @media (min-width: 768px) {
        background-image: url("../image/footer/footer-bg-lg.jpg");
    }
    @media (min-width: 1200px) {
        padding-top: 18rem;
    }
    @media (min-width: 1600px) {
        background-image: url("../image/footer/footer-bg-xl.jpg");
    }

    li {
        margin-bottom: 0.4em;
    }

    a:hover {
        color          : #fff;
        text-decoration: underline;
    }

    .fa {
        display     : inline-block;
        margin-right: 1em;
        font-size   : 1.4em;
    }
    @media (max-width: 768px) {
        .list-unstyled {
            margin-bottom: 3em;
        }
    }
}

.footer-heading {
    text-transform: uppercase;
}
/*
#dogline {
    position        : relative;
    display         : block;
    float           : left;
    width           : 100%;
    height          : 4px;
    background-color: $color-white;

    &:after {
        content          : '';
        position         : absolute;
        bottom           : 4px;
        right            : 0;
        z-index          : 3000;
        display          : block;
        width            : 260px;
        height           : 282px;
        background-image : url("/../../../../image/butcherdog.png");
        background-repeat: no-repeat;
        background-size  : 100%;
        pointer-events   : none;
        @media (max-width: 767px) {
            width : 120px;
            height: 120px;
        }
    }
}
*/