/* product list */
.product-thumb {
    border        : 1px solid #ddd;
    margin-bottom : 8px;
    overflow      : auto;
    position      : relative;
    text-align    : center;
    padding-bottom: 40px;
}

.product-thumb .image {
    text-align: center;
}

.product-thumb .image a {
    display: block;
}

.product-thumb .image a:hover {
    opacity: 0.8;
}

#content .product-thumb img {
    margin-left : auto;
    margin-right: auto;
    width       : auto;
}

.product-thumb .image img {
    margin-left : auto;
    margin-right: auto;
    width       : auto;
}

.product-grid .product-thumb .image {
    float: none;
}
@media (min-width: 767px) {
    .product-list .product-thumb .image {
        float  : left;
        padding: 0 15px;
    }
}

.product-thumb h4 {
    /* font-weight: 700; */
    text-align : center;
    display    : block;
    height     : 60px;
    /* border-bottom: 1px solid #eee; */
    font-size  : 0.9em;
    line-height: 1.5;
}

.product-thumb .caption {
    padding   : 0 8px;
    min-height: 100px;
}

.caption p:nth-child(2) {
    /* height: 38px; */
    display : none;
    float   : left;
    width   : 100%;
    overflow: hidden;
}

.caption p:nth-child(3) {
    height  : 20px;
    display : block;
    float   : left;
    width   : 100%;
    overflow: hidden;
}

.product-list .product-thumb .caption {
    /* margin-left: 230px; */
}
@media (max-width: 1200px) {
    .product-grid .product-thumb .caption {
        min-height: 210px;
        padding   : 0 10px;
    }
}
@media (max-width: 767px) {
    .product-list .product-thumb .caption {
        min-height : 0;
        margin-left: 0;
        padding    : 0 10px;
    }

    .product-grid .product-thumb .caption {
        min-height: 0;
    }
}

.product-thumb .rating {
    padding-bottom: 10px;
}

.rating .fa-stack {
    font-size: 8px;
}

.rating .fa-star-o {
    color    : #999;
    font-size: 15px;
}

.rating .fa-star {
    color    : #FC0;
    font-size: 15px;
}

.rating .fa-star + .fa-star-o {
    color: #E69500;
}

h2.price {
    margin: 0;
}

.product-thumb .price {
    color: #444;
}

.product-thumb .price-new {
    font-weight: 600;
}

.product-thumb .price-old {
    color          : #999;
    text-decoration: line-through;
    margin-left    : 10px;
}

.product-thumb .price-tax {
    color    : #999;
    font-size: 12px;
    display  : block;
}

.product-thumb .button-group {
    border-top      : 1px solid #ddd;
    background-color: #eee;
    position        : absolute;
    width           : 100%;
    bottom          : 0;
}

.product-list .product-thumb .button-group {
    border-left: 1px solid #ddd;
}
@media (max-width: 768px) {
    .product-list .product-thumb .button-group {
        border-left: none;
    }
}

.product-thumb .button-group button {
    width           : 60%;
    border          : none;
    display         : inline-block;
    float           : left;
    background-color: #eee;
    color           : #888;
    line-height     : 38px;
    font-weight     : bold;
    text-align      : center;
    text-transform  : uppercase;
}

.product-thumb .button-group button + button {
    width      : 20%;
    border-left: 1px solid #ddd;
}

.product-thumb .button-group button:hover {
    color           : #444;
    background-color: #ddd;
    text-decoration : none;
    cursor          : pointer;
}
@media (max-width: 1200px) {
    .product-thumb .button-group button,
    .product-thumb .button-group button + button {
        width: 33.33%;
    }
}
@media (max-width: 767px) {
    .product-thumb .button-group button,
    .product-thumb .button-group button + button {
        width: 33.33%;
    }

    .thumbnails {
        overflow  : auto;
        clear     : both;
        list-style: none;
        padding   : 0;
        margin    : 0;
    }

    .thumbnails > li {
        margin-left: 20px;
    }

    .thumbnails {
        margin-left: -20px;
    }

    .thumbnails > img {
        width: 100%;
    }

    .image-additional a {
        margin-bottom: 20px;
        padding      : 5px;
        display      : block;
        border       : 1px solid #ddd;
    }

    .image-additional {
        max-width: 78px;
    }

    .thumbnails .image-additional {
        float      : left;
        margin-left: 20px;
    }
}