/* Top Bar */
.top-bar {
    padding         : 4px 0 3px;
    margin          : 0;
    min-height      : 40px;
    background-color: $color-top-bg;

    &-container {
        display    : flex;
        align-items: center;
    }

    a {
        color: $color-text;
    }
}

.social-media {
    width      : 110px;
    padding-top: 6px;
}

.social-links {
    font-size   : 1.5rem;
    margin-right: 0.5em;
}

.top-icons {
    width     : calc(100% - 110px);
    text-align: right;

    .list-inline {
        margin-bottom: 0;
    }
}

.top-icon {
    span {
        font-size: 0.9rem;
    }
}
/* Header */
.main-header {
    padding-top     : 2em;
    padding-bottom  : 2em;
    background-color: $color-header-bg;
}
@media (min-width: 1023px) {
    .main-header {
        background-image : url("../image/header-bg-full.jpg");
        background-repeat: no-repeat;
    }
}

#logo {
    margin-bottom: 1rem;

    img {
        display     : block;
        width       : auto;
        max-width   : 420px;
        margin-left : auto;
        margin-right: auto;
    }
}
@media (max-width: 970px) {
    #logo {
        img {
            width    : auto;
            max-width: 360px;
        }
    }
}

.searcharea {
    float     : left;
    width     : 23%;
    margin-top: -58px;
}

.cartarea {
    float     : right;
    width     : 23%;
    margin-top: -58px;
}
/* search */
#search {
    margin-bottom: 10px;
}

#search .input-lg {
    height : 36px;
    padding: 1em;
}

#search .btn-lg {
    font-size  : 15px;
    line-height: 18px;
    padding    : 8px 10px;
    text-shadow: 0 1px 0 #FFF;
}
/* cart */
#cart {
    margin-bottom: 10px;
}

#cart > .btn {
    font-size  : 12px;
    line-height: 12px;
    color      : #FFF;
}

#cart.open > .btn {
    background-image: none;
    background-color: #FFFFFF;
    border          : 1px solid #E6E6E6;
    color           : #666;
    box-shadow      : none;
    text-shadow     : none;
}

#cart.open > .btn:hover {
    color: #444;
}

#cart .dropdown-menu {
    background: #eee;
    z-index   : 1001;
}

#cart .dropdown-menu {
    min-width: 100%;
}