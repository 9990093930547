.page-section {
    padding-top   : 2.5rem;
    padding-bottom: 2.5rem;

    &-no-top {
        padding-top   : 0;
        padding-bottom: 2.5em;
    }
}

.texture {
    color           : $color-on-texture;
    background-color: $color-header-bg;
    @media (min-width: 768px) {
        background-image : url("../image/header-bg-full.jpg");
        background-repeat: repeat;
    }
}
/* Homepage */
.home {
    &-container {
        font-size: 1.2rem;
        max-width: 100%;
        overflow : hidden;
    }

    &-intro-top {
        h1 {
            @include styleText;
            width           : 539.5px;
            height          : 131.1px;
            background-image: url("../image/we_are_cotswold_raw.svg");
        }
        @media (max-width: 990px) {
            &-text {
                margin-bottom: 1em;
                text-align   : center;
            }

            h1 {
                width        : 100%;
                margin-bottom: 1em;
            }
        }
        @media (max-width: 480px) {
            height: 96.1px;
        }
    }

    &-intro-bottom {
        h2 {
            padding            : 0.391em;
            max-width          : 373px;
            margin-bottom      : 1em;
            font-size          : 2em;
            color              : $color-white;
            text-align         : center;
            text-transform     : uppercase;
            background-image   : url("../image/whyraw-bg.png");
            background-repeat  : no-repeat;
            background-size    : contain;
            background-position: center;
        }
        @media (max-width: 768px) {
            text-align: center;

            h2 {
                margin-left : auto;
                margin-right: auto;
            }
        }
    }

    &-video-container {
        p {
            max-width: 643px;
            margin   : auto;
        }

        h2 {
            @include styleText;
            width           : 420px;
            height          : 150px;
            margin-left     : auto;
            margin-right    : auto;
            background-image: url("../image/our_recipes.svg");
        }

        iframe {
            //padding      : 2px;
            border-radius: 5px;
            // padding-bottom: 1em;
            border       : 1px solid #ccc;
        }
    }

    &-show-only {
        display: none;

        .common-home &,
        .new-design-page & {
            display: block;
        }
    }
}

.featured-product {
    .owl-carousel {
        //padding-top: 2.3em;
    }

    .product-thumb {
        padding: 1em;
        border : none;
    }

    h4 {
        font-size: 1rem;
    }
}

.form-control {
    height: 35px;
}

.slogan {
    display  : block;
    width    : 336px;
    max-width: 100%;
    margin   : 0 auto;
    transform: translateY(-20px);
    @media (min-width: 980px) {
        transform: translateY(-25px);
    }
}